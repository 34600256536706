<template>
  <div>
    <v-layout v-if="project">
      <v-btn
        icon
        class="primary--text mt-1 mr-2"
        :to="`/`"
        router
        exact
      >
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Project '{{ project.name }}'</page-title>

      <div class="ml-4 mt-1 mb-2">       
        <v-btn class="primary--text mr-3" text @click="refresh()">
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>

        <v-btn text class="primary--text" @click="deleteProjectIntent()">
          <v-icon class="mr-1">delete</v-icon>
          Delete
        </v-btn>
      </div>
    </v-layout>

    <v-layout>
      <page-subtitle
        >View and edit your project details and memberships.</page-subtitle
      >
    </v-layout>

    <!-- delete confirmation modal -->
      <v-dialog v-if="project" v-model="removeProjectModal" max-width="500px">
        <v-card>
          <v-card-title>Confirmation Required</v-card-title>
          <v-card-text>
            Are you sure want to delete project '{{ project.name }}'?
            This will remove all devices, namespaces. applications, secrets,
            provisioning tokens, etc.
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              v-on:click.native="removeProjectModal = false"
              class="primary--text"
              >Cancel</v-btn
            >
            <v-spacer> </v-spacer>
            <v-btn
              v-on:click.native="deleteProjectConfirmed()"
              class="error white--text"
            >
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <v-layout class="mb-3" wrap>
      <v-flex lg8 md12 sm12 pr-2>
        <v-card v-if="project" flat outlined>
          <v-card-title>Project Info</v-card-title>

          <v-card-text>
            <v-list dense v-if="project">
              <v-list-item>
                <v-list-item-content>
                  <span class="font-weight-bold">Project ID</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ project.id }}
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <span class="font-weight-bold">Project Name</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ project.name }}
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <span class="font-weight-bold">Created At</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ project.createdAt | date }}
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <span class="font-weight-bold">Devices Quota</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  <v-progress-linear
                    rounded            
                    :color="getQuotaColour(project.deviceCount/project.quota.devices * 100)"
                    height="18"
                    :value="(project.deviceCount/project.quota.devices * 100)"
                  >
                    <strong>{{ project.deviceCount }}/{{ project.quota.devices }}</strong>
                  </v-progress-linear>
                  <!-- {{ project.createdAt | date }} -->
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <span class="font-weight-bold">Namespaces Quota</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  <v-progress-linear
                    rounded
                    :color="getQuotaColour(project.namespaceCount/project.quota.namespaces * 100)"
                    height="18"
                    :value="(project.namespaceCount/project.quota.namespaces * 100)"
                  >
                    <strong>{{ project.namespaceCount }}/{{ project.quota.namespaces }}</strong>
                  </v-progress-linear>
                  <!-- {{ project.createdAt | date }} -->
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <span class="font-weight-bold">Applications Quota</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  <v-progress-linear
                    rounded
                    :color="getQuotaColour(project.applicationCount/project.quota.applications * 100)"
                    height="18"
                    :value="(project.applicationCount/project.quota.applications * 100)"
                  >
                    <strong>{{ project.applicationCount }}/{{ project.quota.applications }}</strong>
                  </v-progress-linear>
                  <!-- {{ project.createdAt | date }} -->
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <span class="font-weight-bold">Secrets Quota</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  <v-progress-linear
                    rounded
                    :color="getQuotaColour(project.secretCount/project.quota.secrets * 100)"
                    height="18"
                    :value="(project.secretCount/project.quota.secrets * 100)"
                  >
                    <strong>{{ project.secretCount }}/{{ project.quota.secrets }}</strong>
                  </v-progress-linear>
                  <!-- {{ project.createdAt | date }} -->
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

            </v-list>            
          </v-card-text>

          <!-- <v-card-text>
            <pre>{{ project }}</pre>
             <br/>
            <span>Memberships:</span>
            <pre>{{ projectMemberships }}</pre>
          </v-card-text> --> 

        </v-card>
      </v-flex>
    </v-layout>

    <v-layout class="mb-3" wrap>
      <v-flex lg8 md12 sm12 pr-2>
        <project-namespaces-edit v-if="project" :project="project"></project-namespaces-edit>
      </v-flex>
    </v-layout>

    <v-layout class="mb-3" wrap>
      <v-flex lg8 md12 sm12 pr-2>
        <project-members-edit v-if="project" :project="project"></project-members-edit>
      </v-flex>
    </v-layout>

  </div>
</template>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';
import ProjectMembersEdit from './ProjectMembersEdit';
import ProjectNamespacesEdit from './ProjectNamespacesEdit';

export default {
  components: {
    PageSubtitle,
    PageTitle,
    ProjectMembersEdit,
    ProjectNamespacesEdit
  },

  data() {
    return {
      dialog: false,
      removeProjectModal: false,
    }
  },

  computed: {
    projectId() {
      return this.$route.params.project
    },
    project() {
      return this.$store.state.project.project
    },
    loading() {
      return this.$store.state.project.loading
    },
    error() {
      return this.$store.state.project.error
    },
    selectedProjectId() {
      return this.$store.state.project.selectedProjectId
    }
  },

  watch: {
    removeProjectModal(val) {
      val || this.cancelDeletion()
    },
  },

  mounted() {
    this.refresh()
  },

  methods: {
    refresh() {
      let q = {
        projectId: this.projectId,
      }
      this.$store.dispatch('GetProject', q)
      this.$store.dispatch('ListMembershipsByProject', q)

      let nsq = {
        project: this.selectedProjectId
      }
      this.$store.dispatch('ListNamespaces', nsq)
    },
    deleteProjectIntent() {
      this.removeProjectModal = true
    },
    deleteProjectConfirmed() {
      this.$store.dispatch('DeleteProject', this.project).then(() => {
        if (this.project.id === this.selectedProjectId) {
          this.$store.dispatch('SelectProject', '')
        }
        // this.refresh()
        this.$router.push({
          name: 'home'
        })
        // closing confirmation modal
        this.removeProjectModal = false
      })
    },
    cancelDeletion() {
      this.removeProjectModal = false
    },
    getQuotaColour(usage) {
      if (usage < 70) {
        return 'green accent-3'
      }
      if (usage < 80) {
        return 'amber lighten-1'
      }

      // return 'pink lighten-1'
      return 'red darken-3'
    }
  }
}

</script>