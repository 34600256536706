<template>
    <div>
    <v-card v-if="project" flat outlined>
      <v-card-title
        >Namespaces
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              v-on="tooltip"
              href="https://docs.synpse.net/manage/projects"
              target="_blank"
              rel="noopener"
            >
              <v-icon color="grey" small>help</v-icon>
            </v-btn>
          </template>
          <span> View and manage project namespaces </span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="namespaces"
          sort-by="key"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-layout>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    class="primary--text mb-2"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="project.namespaceCount >= project.quota.namespaces"
                  >
                    New Namespace
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <form v-on:submit.prevent="save()">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="editedItem.name"
                              label="Namespace Name"
                            ></v-text-field>
                          </v-col>                          
                        </v-row>
                      </v-container>
                      <v-btn v-show="false" hidden type="submit"></v-btn>
                    </form>
                    <div v-show="error">
                      <v-alert type="error" outlined>
                        {{ error }}
                      </v-alert>
                    </div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn class="primary--text" text @click="close">
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="primary--text" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure?</v-card-title>
                  <v-card-text>
                    This action will remove namespace '{{
                      editedItem.name
                    }}'' from the project. This will delete all applications and secrets.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="primary--text" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn class="primary--text" text @click="deleteItemConfirm"
                      >Delete</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-layout>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <router-link
                :to="{
                  name: 'applications',
                  params: {
                    project: item.projectId,
                    namespace: item.id,
                
                  },
                }"
                >{{ item.name }}</router-link
              >
           
          </template>       
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | date }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- TODO: add namespace name edit if supported -->
            <!-- <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon> -->
            <v-icon            
              small
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </template>
          <template v-slot:no-data> No Namespaces </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
    project: Object
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Created At', value: 'createdAt' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: ''
      },
      defaultItem: {
        name: ''
      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  computed: {
    projectId() {
      return this.$route.params.project
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Namespace' : 'Edit Namespace'
    },
    namespaces() {
      return this.$store.state.namespace.namespaces
    },
    loading() {
      return this.$store.state.namespace.loading
    },
    error() {
      return this.$store.state.namespace.error
    }
  },

  mounted() {
    this.refresh()
  },

  methods: {
    refresh() {
      let q = {
        project: this.projectId,
      }
      this.$store.dispatch('ListNamespaces', q)
    },
    editItem(item) {
      this.editedIndex = this.namespaces.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.namespaces.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      // Delete membership (avoid deleting yourself)
      let payload = {
        projectId: this.project.id,
        id: this.editedItem.id
      }
      await this.$store.dispatch('DeleteNamespace', payload)

      this.$store.dispatch('Notify', `Namespace deleted`)

      this.refresh()
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      let payload = {
        projectId: this.project.id,
        name: this.editedItem.name
      }
      await this.$store.dispatch('CreateNamespace', payload)

      if (this.error) {
        // Displaying error
        return
      }

      this.$store.dispatch('Notify', `Namespace '${payload.name}' created`)

      this.refresh()

      this.close()
    },
  }
}
</script>